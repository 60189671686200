import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const Title = (props) => {
  return (
    <Typography variant="h5" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
};
Title.propTypes = {
  children: PropTypes.node,
};

export default Title;