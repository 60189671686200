import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import openSocket from "../../services/socket-io";
import api from "../../services/api";

const useAuth = () => {
  // !
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  // ---

  // @
  // Request Middleware
  api.interceptors.request.use(
    config => {
      const token = Cookies.get("token");

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        setIsAuth(true);
      }

      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  // Response Middleware
  api.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await api.post("/auth/refresh_token");

        if (data) {
          Cookies.set("token", data.token, {
            domain: ".sincrotools.com.br",
          });

          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }

        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        Cookies.remove("token", {
          domain: ".sincrotools.com.br",
        });

        api.defaults.headers.Authorization = undefined;

        setIsAuth(false);
      }

      return Promise.reject(error);
    }
  );

  // Refresh Token
  useEffect(() => {
    const token = Cookies.get("token");

    (async () => {
      if (token) {
        try {
          const { data } = await api.post("/auth/refresh_token");

          api.defaults.headers.Authorization = `Bearer ${data.token}`;

          setIsAuth(true);
          setUser(data.user);
        } catch (err) {
          console.error(err);
        }
      }

      setLoading(false);
    })();
  }, []);

  // Connect to Socket
  useEffect(() => {
    const socket = openSocket();

    socket.on("user", data => {
      if (data.action === "update" && data.user.id === user.id) {
        setUser(data.user);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  // ---

  // 3️⃣ Functions
  const handleLogin = async (userData) => {
    setLoading(true);

    try {
      const { data } = await api.post("/auth/login", userData);

      Cookies.set("token", data.token, {
        domain: ".sincrotools.com.br",
      });

      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setUser(data.user);
      setIsAuth(true);

      const to = Cookies.get("redirect_to") ?? "/";
      history.push(to);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");

      setIsAuth(false);
      setUser({});

      Cookies.remove("token", {
        domain: ".sincrotools.com.br",
      });

      api.defaults.headers.Authorization = undefined;

      history.push("/login");

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return { isAuth, user, loading, handleLogin, handleLogout };
};

export default useAuth;
