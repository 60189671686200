import { makeStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";

export const makeConnectionsListStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: 0,
    overflowY: "scroll",
    marginBottom: "20px",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));
