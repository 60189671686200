import React, { useContext, useEffect } from "react";
import Cookies from "js-cookie";

import { AuthContext } from "../../context/Auth/AuthContext";

import BackdropLoading from "../../components/BackdropLoading";

const Login = () => {
  // !
  // Contexts
  const { handleLogin, loading } = useContext(AuthContext);

  // @
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Email
      const email_cliente = Cookies.get("email_cliente");
      const email_supervisor = Cookies.get("email_supervisor");
      const email_agente = Cookies.get("email_agente");

      const user_email_encrypted = email_cliente || email_supervisor || email_agente;

      let user_email = null;
      let user_password = null;

      if (user_email_encrypted !== null) {
        user_email = atob(user_email_encrypted);
        user_password = user_email;
      }
      // Profile
      let user_profile;
      if (email_cliente !== undefined) {
        user_profile = "admin";
      }
      if (email_supervisor !== undefined) {
        user_profile = "supervisor";
      }
      if (email_agente !== undefined) {
        user_profile = "agente";
      }

      // console.log(user_email)
      // @ Try to login if user and password
      if (user_email !== null && user_password !== null) {
        handleLogin({ email: user_email, password: user_password, profile: user_profile });
      }

      return () => clearInterval(intervalId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [handleLogin]);

  // :
  return (
    <>
      {loading && <BackdropLoading />}
    </>
  );
};

export default Login;
