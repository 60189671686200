import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";
import PropTypes from "prop-types";
// translate
import { i18n } from "../translate/i18n";
// services
import api from "../services/api";
// errors
import toastError from "../errors/toastError";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// components
import TicketOptionsMenu from "./TicketOptionsMenu";
import ButtonWithSpinner from "./ButtonWithSpinner";
import TransferTicketModal from "./TransferTicketModal";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const TicketActionButtons = ({ ticket }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // Styles
  const classes = useStyles();
  // History
  const history = useHistory();
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);	
  // Ref
  const isMounted = useRef(true);
  // ...
  const ticketOptionsMenuOpen = Boolean(anchorEl);

  // @
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOpenTicketOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);

    const isConfirmed = (status !== "closed") ? true : window.confirm("Tem certeza de que deseja finalizar?");

    if (isConfirmed) {
      try {
        await api.put(`/tickets/${ticket.id}`, {
          status: status,
          userId: userId || null,
        }, {
          params: {
            channel: ticket.channel
          }
        });

        setLoading(false);
        if (status === "open") {
          history.push(`/tickets/${ticket.id}`);
        } else {
          history.push("/tickets");
        }
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    } else {
      setLoading(false);
    }
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };
  const handleOpenTransferModal = () => {
    setTransferTicketModalOpen(true);
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay />}
          size="small"
          onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      )}

      {ticket.status === "open" && user.profile === "agente" && (
        <>
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleOpenTransferModal}
          >
            {i18n.t("ticketOptionsMenu.transfer")}
          </ButtonWithSpinner>
          <TransferTicketModal
            modalOpen={transferTicketModalOpen}
            onClose={handleCloseTransferTicketModal}
            ticketId={ticket.id}
            connectionId={ticket.whatsappId}
            channel={ticket.channel}
          />
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.resolve")}
          </ButtonWithSpinner>
        </>
      )}
      {ticket.status === "open" && user.profile === "supervisor" && (
        <>
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenTransferModal}
          >
            {i18n.t("ticketOptionsMenu.transfer")}
          </ButtonWithSpinner>
          <TransferTicketModal
            modalOpen={transferTicketModalOpen}
            onClose={handleCloseTransferTicketModal}
            ticketId={ticket.id}
            connectionId={ticket.whatsappId}
            channel={ticket.channel}
          />
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}

      {/* {ticket.status === "pending" && user.profile !== "supervisor" && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )} */}
      {ticket.status === "pending" && user.profile === "supervisor" && (
        <>
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenTransferModal}
          >
            {i18n.t("ticketOptionsMenu.transfer")}
          </ButtonWithSpinner>
          <TransferTicketModal
            modalOpen={transferTicketModalOpen}
            onClose={handleCloseTransferTicketModal}
            ticketId={ticket.id}
            connectionId={ticket.whatsappId}
            channel={ticket.channel}
          />
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
    </div>
  );
};
TicketActionButtons.propTypes = {
  ticket: PropTypes.object.isRequired,
};

export default TicketActionButtons;
