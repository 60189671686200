import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const QueueSelect = ({ selectedQueueIds, onChange }) => {
  // !
  // Styles
  const classes = useStyles();
  // States
  const [queues, setQueues] = useState([]);

  // @
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue/");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = e => {
    let ultimo = e.target.value[e.target.value.length - 1];
    let target = [ultimo];
    e.target.value = target;
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>	
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
        <Select
          multiple
          labelWidth={60}
          value={selectedQueueIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 && selected.map(id => {
                const queue = queues.find(q => q.id === id);
                return queue ? (
                  <Chip
                    key={id}
                    style={{ backgroundColor: queue.color }}
                    variant="outlined"
                    label={queue.name}
                    className={classes.chip}
                  />
                ) : null;
              })}
            </div>
          )}
        >
          {queues.map(queue => (		
            <MenuItem key={queue.id} value={queue.id}>
              {queue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
QueueSelect.propTypes = {
  selectedQueueIds: PropTypes.array,
  onChange: PropTypes.func,
};

export default QueueSelect;
