import React, { useContext } from "react";
import Cookies from "js-cookie";
// components
import MainContainer from "../../components/MainContainer";

import ConnectionsListFacebook from "../../components/ConnectionsListFacebook.js";
import ConnectionsListWhatsapp from "../../components/ConnectionsListWhatsapp.js";
import ConnectionsListInstagram from "../../components/ConnectionsListInstagram.js";
// contexts
import { AuthContext } from "../../context/Auth/AuthContext.js";

const Connections = () => {
  // !
  // Contexts
  const { user, handleLogout } = useContext(AuthContext);

  // ?
  // Email
  const email_agente = Cookies.get("email_agente");
  const email_cliente = Cookies.get("email_cliente");
  const email_supervisor = Cookies.get("email_supervisor");
  // Profile
  let user_profile;
  if (email_cliente) {
    user_profile = "admin";
  }
  else if (email_supervisor) {
    user_profile = "supervisor";
  }
  else if (email_agente) {
    user_profile = "agente";
  }

  const user_email_encrypted = email_agente || email_cliente || email_supervisor;
  const user_email = atob(user_email_encrypted);
  if ( (user?.email && user.email !== user_email) || user?.profile !== user_profile) {
    handleLogout();
  }

  return (
    <MainContainer>
      <ConnectionsListWhatsapp />
      <ConnectionsListFacebook />
      <ConnectionsListInstagram />
    </MainContainer>
  );
};

export default Connections;
