import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
import Cookies from "js-cookie";

function connectToSocket() {
  const token = Cookies.get("token") || "";

  const socket = openSocket(getBackendUrl(), {
    query: {
      auth_token: token,
    },
  });

  return socket;
}

export default connectToSocket;
