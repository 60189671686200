import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  MainHeaderButtonsWrapper: {
    flex: "none",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const MainHeaderButtonsWrapper = ({ children }) => {
  // !
  // Styles
  const classes = useStyles();

  return <div className={classes.MainHeaderButtonsWrapper}>{children}</div>;
};
MainHeaderButtonsWrapper.propTypes = {
  children: PropTypes.node,
};

export default MainHeaderButtonsWrapper;
