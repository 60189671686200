import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const FlowSelect = ({ selectedFlowIds, onChange }) => {
  // !
  // State
  const [flows, setFlows] = useState([]);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/flow/");
        setFlows(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = e => {
    let ultimo = e.target.value[e.target.value.length - 1];
    let target = [ultimo];
    e.target.value = target;
    onChange(e.target.value);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("flowSelect.inputLabel")}</InputLabel>
        <Select
          multiple
          labelWidth={60}
          value={selectedFlowIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 && selected.map(id => {
                const flow = flows.find(q => q.id === id);
                return flow ? (
                  <Chip
                    key={id}
                    style={{ backgroundColor: "#008000" }}
                    variant="outlined"
                    label={flow.name}
                    className={classes.chip}
                  />
                ) : null;
              })}
            </div>
          )}>
          {flows.map(flow => (
            <MenuItem key={flow.id} value={flow.id}>
              {flow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
FlowSelect.propTypes = {
  selectedFlowIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default FlowSelect;
