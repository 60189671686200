import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import AllInboxRoundedIcon from "@material-ui/icons/AllInboxRounded";
// components
import NewTicketModal from "./NewTicketModal";
import TicketsList from "./TicketsList";
import TabPanel from "./TabPanel";
import TicketsQueueSelect from "./TicketsQueueSelect";
import TicketsAgentsSelect from "./TicketsAgentsSelect";
import TicketsConnectionSelect from "./TicketsConnectionSelect";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 11,
    outlineStyle: "none",
    border: "none",
    padding: "10px",
  },

  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  searchContainer: {
    display: "flex",
    padding: "10px",
    borderBottom: "2px solid rgba(0, 0, 0, .12)",
  },
}));

const TicketsManagerAll = () => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // State
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabList, setTabList] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);

  const [selectedQueueIds, setSelectedQueueIds] = useState(user.queues.map((q) => q.id) || []);

  const [agentList, setAgentList] = useState([]);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);

  const [connectionList, setConnectionList] = useState([]);
  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);

  // Styles
  const classes = useStyles();

  // @ Set `ShowAllTickets` if user is supervisor
  useEffect(() => {
    if (user.profile.toUpperCase() === "SUPERVISOR") {
      setShowAllTickets(true);
    }
  }, []);
  // @ Fetch and set `AgentList`
  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await api.get("/users/");

      setAgentList(data.users);
    };

    fetchAgents();
  }, []);
  // @ Fetch and set `ConnectionList`
  useEffect(() => {
    const fetchConnections = async () => {
      const { data } = await api.get("/whatsapp/supervisor/");

      setConnectionList(data);
    };

    fetchConnections();
  }, []);

  // 3️⃣ functions
  const handleSearch = (e) => {
    const searchedTerm = e.target.value;

    setSearchParam(searchedTerm);
    if (searchedTerm === "") {
      setTab("open");
    } else if (tab !== "search") {
      setTab("search");
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
    setTabList(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabList !== status) {
      return { width: 0, height: 0 };
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.searchContainer}>
        <SearchIcon className={classes.searchIcon} />
        <input
          type="text"
          placeholder={i18n.t("tickets.search.placeholder")}
          className={classes.searchInput}
          value={searchParam}
          onChange={handleSearch}
        />
      </Paper>
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"pending"}
            icon={<HourglassEmptyRoundedIcon />}
            label={i18n.t("ticketsList.pendingHeader2")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<AllInboxRoundedIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <TicketsQueueSelect
          userQueues={user?.queues}
          selectedQueueIds={selectedQueueIds}
          onChange={(values) => setSelectedQueueIds(values)}
        />
        {tab === "pending" ? (
          <TicketsConnectionSelect
            connectionsWhatsapp={connectionList}
            selectedConnectionIds={selectedConnectionIds}
            onChange={(value) => setSelectedConnectionIds(value)}
          />
        ) : (
          <TicketsAgentsSelect
            userAgents={agentList}
            selectedAgentIds={selectedAgentIds}
            onChange={(values) => setSelectedAgentIds(values)}
          />
        )}
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}> 
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            selectedAgentIds={selectedAgentIds}
            selectedConnectionIds={selectedConnectionIds}
            style={applyPanelStyle("open")}
          />
        </Paper> 
      </TabPanel>

      <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
        <TicketsList
          status="pending"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          selectedAgentIds={selectedAgentIds}
          selectedConnectionIds={selectedConnectionIds}
          style={applyPanelStyle("pending")}
        />
      </TabPanel>

      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          selectedAgentIds={selectedAgentIds}
          selectedConnectionIds={selectedConnectionIds}
        />
      </TabPanel>

      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          selectedAgentIds={selectedAgentIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerAll;