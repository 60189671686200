import React from "react";
import Cookies from "js-cookie";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// contexts
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
// layouts
import LoggedInLayout from "../layout";
// pages
import Tickets from "../pages/Tickets/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";

import Route from "./Route";

const Routes = () => {
  const redirectTo = Cookies.get("redirect_to") ?? "/connections";

  return (
    <HashRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" isPrivate>
                <Redirect to={redirectTo} />
              </Route>
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </HashRouter>
  );
};

export default Routes;
