import React, { useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";

import { Block } from "@material-ui/icons";

import { Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
// assets
import facebookIcon from "../assets/channels/Facebook_Logo_Primary.png";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// errors
// import toastError from "../errors/toastError";
// components
// import ButtonWithSpinner from "./ButtonWithSpinner";
import MarkdownWrapper from "./MarkdownWrapper";
// services
// import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  primaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  secondaryTop: {
    display: "flex",
    width: "100%",
  },
  secondaryBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  contactNameText: {
    flexGrow: 1,
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    lineHeight: "1.7",
  },

  statusBadge: {
    alignSelf: "center",
    marginRight: 5,
    "& span": {
      transform: "unset",
      transformOrigin: "unset",
      position: "relative"
    }
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  channelInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  userTag: {
    marginRight: 5,
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
    height: "22px",
    whiteSpace: "nowrap",
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },
}));

const TicketListItemFacebook = ({ ticket }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // History
  const history = useHistory();
  // Params
  const { ticketId } = useParams();
  // Ref
  const isMounted = useRef(true);
  // State
  // const [loading, setLoading] = useState(false);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  /*
  const handleAcceptTicket = async id => {
    setLoading(true);

    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      }, {
        params: {
          channel: ticket.channel
        }
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }

    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${id}`);
  };
  */

  const handleSelectTicket = id => {
    history.push(`/tickets/${id}?channel=facebook`);
  };

  return (
    <React.Fragment key={ticket.id + "facebook"}>
      <ListItem
        dense
        button
        onClick={() => {
          if (ticket.status === "pending" && user.profile !== "supervisor") return;
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <span className={classes.primaryWrapper}>
              <Typography
                className={classes.contactNameText}
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact.name}
              </Typography>

              <Typography
                className={classes.lastMessageTime}
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                )}
              </Typography>
            </span>
          }
          secondary={
            <span className={classes.secondaryWrapper}>
              <span className={classes.secondaryTop}>
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? (
                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                  ) : (
                    <>
                      <Block
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                      <i>Esta mensagem foi deletada.</i>
                    </>
                  )}
                </Typography>

                <Badge
                  className={classes.newMessagesCount}
                  badgeContent={ticket.unreadMessages}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                />
              </span>

              <span className={classes.secondaryBottom}>
                {ticket.status === "pending" && (
                  <div></div>
                )}
                {ticket.status === "open" && (
                  <div></div>
                )}
                {ticket.status === "closed" && (
                  <Badge
                    className={classes.statusBadge}
                    badgeContent={i18n.t("tickets.status.closed")}
                    color="error"
                    title={i18n.t("ticketsList.statusTooltip")}
                  />
                )}

                <div className={classes.channelInfo}>
                  <div className={classes.userTag} title={i18n.t("ticketsList.connectionTitle")}>
                    {ticket.access.name}
                  </div>
                  <img alt="Channel" src={facebookIcon} width="16px" height="16px" />
                </div>
              </span>
            </span>
          }
        />
        {/* {ticket.status === "pending" && user.profile !== "supervisor" && (
          <ButtonWithSpinner
            color="primary"
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={() => handleAcceptTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.accept")}
          </ButtonWithSpinner>
        )} */}
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

TicketListItemFacebook.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketListItemFacebook;
