import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";
import PropTypes from "prop-types";
// services
import api from "../services/api_file";

const useStyles = makeStyles(() => ({
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingBottom: 10
  },
}));

const urlFile = process.env.REACT_APP_BACKEND_URL_FILE;

const ModalImageCors = ({ imageUrl }) => {
  // !
  // States
  const [fetching, setFetching] = useState(true);
  const [blobUrl, setBlobUrl] = useState("");
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    if (!imageUrl) return;

    const fetchImage = async () => {
      const { data, headers } = await api.get(urlFile+imageUrl, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(
        new Blob([data], { type: headers["content-type"] })
      );
      setBlobUrl(url);
      setFetching(false);
    };
    fetchImage();
  }, [imageUrl]);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={fetching ? imageUrl : blobUrl}
      medium={fetching ? imageUrl : blobUrl}
      large={fetching ? imageUrl : blobUrl}
      alt="image"
    />
  );
};
ModalImageCors.propTypes = {
  imageUrl: PropTypes.string,
};

export default ModalImageCors;
