import React from "react";
import PropTypes from "prop-types";

const TabPanel = ({ children, value, name, ...rest }) => {
  if (value === name) {
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${name}`}
        aria-labelledby={`simple-tab-${name}`}
        {...rest}
      >
        <>{children}</>
      </div>
    );
  }

  return null;
};
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default TabPanel;
