import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
// errors
import toastError from "../errors/toastError";
// services
import openSocket from "../services/socket-io";
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const QrcodeModal = ({ open, onClose, whatsAppId, companyId }) => {
  // !
  // State
  const [qrCode, setQrCode] = useState("");

  // @
  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    // ?
    if (!whatsAppId) return;
    if (!companyId) return;

    // !
    const socket = openSocket();

    // @
    // @ Subscribe to channels
    socket.on("connect", () => {
      return socket.emit("joinConnections", `connections-${companyId}`);
    });

    // @ Receive events
    socket.on("whatsappSession", data => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, companyId, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
      <DialogContent>
        <Paper elevation={0}>
          <Typography color="primary" gutterBottom>
            {i18n.t("qrCode.message")}
          </Typography>
          {qrCode ? (
            <QRCode value={qrCode} size={256} />
          ) : (
            <span>Waiting for QR Code</span>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};
QrcodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  whatsAppId: PropTypes.string,
  companyId: PropTypes.string.isRequired,
};

export default React.memo(QrcodeModal);
