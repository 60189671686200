import { toast } from "react-toastify";
// translations
import { i18n } from "../translate/i18n";

const toastError = err => {
  const errorMsg = err.response?.data?.message || err.response?.data?.error;

  console.error(errorMsg);

  if (errorMsg) {
    if (i18n.exists(`backendErrors.${errorMsg}`)) {
      toast.error(i18n.t(`backendErrors.${errorMsg}`), {
        toastId: errorMsg,
      });
    } else {
      toast.error(errorMsg, {
        toastId: errorMsg,
      });
    }
  }
};

export default toastError;
