import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
// translate
import { i18n } from "../translate/i18n";

const TicketsAgentsSelect = ({
  userAgents,
  selectedAgentIds = [],
  onChange,
  multiple = true,
}) => {
  // @
  const handleChange = e => {
    onChange(e.target.value);
  };
  const renderValue = selected => {
    if (!selected || selected.length === 0 || multiple) {
      return i18n.t("ticketsAgentsSelect.placeholder");
    }

    return Array.isArray(selected)
      ? selected
        .map(id => userAgents.find(agent => agent.id === id)?.name)
        .join(", ")
      : userAgents.find(agent => agent.id === selected)?.name;
  };

  return (
    <div style={{ width: 120, marginTop: -4 }}>
      <FormControl fullWidth margin="dense">
        <Select
          multiple={multiple}
          displayEmpty
          variant="outlined"
          value={selectedAgentIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={renderValue}
        >
          {userAgents?.length > 0 && userAgents.map(agent => (
            <MenuItem dense key={agent.id} value={agent.id}>
              <Checkbox
                size="small"
                color="primary"
                checked={Array.isArray(selectedAgentIds) ? selectedAgentIds.indexOf(agent.id) > -1 : selectedAgentIds === agent.id}
              />
              <ListItemText primary={agent.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
TicketsAgentsSelect.propTypes = {
  userAgents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  selectedAgentIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default TicketsAgentsSelect;
