import React, { createContext } from "react";
import PropTypes from "prop-types";
// hooks
import useWhatsApps from "../../hooks/useWhatsApps";

const WhatsAppsContext = createContext();

const WhatsAppsProvider = ({ children }) => {
  // !
  const { loading, whatsApps } = useWhatsApps();

  return (
    <WhatsAppsContext.Provider value={{ whatsApps, loading }}>
      {children}
    </WhatsAppsContext.Provider>
  );
};
WhatsAppsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WhatsAppsContext, WhatsAppsProvider };
