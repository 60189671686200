import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { Menu } from "@material-ui/core";
import PropTypes from "prop-types";
// context
import { ReplyMessageContext } from "../context/ReplyingMessage/ReplyingMessageContext";
// errors
import toastError from "../errors/toastError";
// translate
import { i18n } from "../translate/i18n";
// services
import api from "../services/api";
// components
import ConfirmationModal from "./ConfirmationModal";


const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  // !
  // Context
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  // State
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  // @
  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`, {
        params: {
          channel: "facebook"
        }
      });
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
    handleClose();
  };

  return (
    <>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {message.isEcho && (
          <MenuItem onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        <MenuItem onClick={hanldeReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
      </Menu>
    </>
  );
};
MessageOptionsMenu.propTypes = {
  message: PropTypes.object.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
};

export default MessageOptionsMenu;
