import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
// contexts
import { AuthContext } from "../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../context/ReplyingMessage/ReplyingMessageContext";
// errors
import toastError from "../errors/toastError";
// hooks
import { useQuery } from "../hooks/useQuery";
// services
import openSocket from "../services/socket-io";
import api from "../services/api";
// components
import ContactDrawer from "./ContactDrawer";
import MessageInput from "./MessageInput";
import TicketHeader from "./TicketHeader";
import TicketInfo from "./TicketInfo";
import TicketActionButtons from "./TicketActionButtons";
import MessagesList from "./MessagesList";
import MessagesListFacebookPages from "./MessagesListFacebookPages";
import MessagesListInstagram from "./MessagesListInstagram";
import MessageInputFacebookPages from "./MessageInputFacebookPages";
import MessageInputInstagram from "./MessageInputInstagram";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "199%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // History
  const history = useHistory();
  // Params
  const { ticketId } = useParams();
  // Query
  const query = useQuery();
  // State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  // Styles
  const classes = useStyles();

  // @ Get route query
  const channel = query.get("channel");

  // @
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const params = {
            channel
          };

          const { data } = await api.get("/tickets/" + ticketId, { params });

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchTicket();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        console.log("Ticket updated", data.ticket);
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // # Omnichannel
  const renderMessagesList = (channel) => {
    if (channel === "facebook") {
      return <MessagesListFacebookPages ticketId={ticketId} />;
    } else if (channel === "instagram") {
      return <MessagesListInstagram ticketId={ticketId} />;
    } else {
      return <MessagesList ticketId={ticketId} isGroup={ticket.isGroup ?? false} />;
    }
  };
  const renderMessageInput = () => {
    
    if (user.profile === "supervisor") {
      return null;
    }
    
    if (channel === "facebook") {
      return <MessageInputFacebookPages ticketStatus={ticket.status} />;
    } else if (channel === "instagram") {
      return <MessageInputInstagram ticketStatus={ticket.status} />;
    } else {
      return <MessageInput ticketStatus={ticket.status} queue={ticket.queue}/>;
    }
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: channel === "facebook" || channel === "instagram" || drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} />
          </div>
        </TicketHeader>

        <ReplyMessageProvider>
          {renderMessagesList(channel)}          

          {renderMessageInput()}
        </ReplyMessageProvider>
      </Paper>

      {channel !== "facebook" && channel !== "instagram" && (
        <ContactDrawer
          open={drawerOpen}
          handleDrawerClose={handleDrawerClose}
          contact={contact}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Ticket;
