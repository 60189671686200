import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
// components
import QueueSelect from "./QueueSelect";
import FlowSelect from "./FlowSelect";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translations
import { i18n } from "../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  expediente: {
    display: "flex",
    flexWrap: "wrap",
  },
  tituloReceberMsg: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  reabrirTicket: {
    fontSize: 12,
    display: "flex",
    marginLeft: theme.spacing(2),
  },
  textSize: {
    fontSize: 12,
  },
  paperReceberMsg: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  diasSemana: {
    marginLeft: theme.spacing(1),
  },
  hora: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 250,
  },
  textoExpediente: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: "100%",
  },
}));

const ConnectionEditInstagramModal = ({ open, onClose, onConnect, page }) => {
  const initialState = {
    farewellMessage: ""
  };
  // !
  // States
  const [facebookPage, setFacebookPage] = useState(initialState);

  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedFlowIds, setSelectedFlowIds] = useState([]);

  const [defineFlow, setDefineFlow] = useState(false);
  const [defineSetor, setDefineSetor] = useState(false);

  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    const fetchSession = async () => {
      setDefineFlow(false);
      setSelectedFlowIds([]);
      setSelectedQueueIds([]);

      try {
        if (page === null) {
          return;
        }

        const { data } = await api.get(`/facebook/login/${page.username}`, {
          params: {
            connection: "instagram"
          }
        });

        // !
        setFacebookPage(data);

        const queueId = data.queueId;
        setSelectedQueueIds([queueId]);

        const flowId = data.flowId;
        setSelectedFlowIds([flowId]);

        if (flowId){
          setDefineSetor(false);

          setDefineFlow(true);
        } else {
          setDefineSetor(true);

          setDefineFlow(false);
          setSelectedFlowIds([]);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchSession();
  }, [page]);

  const handleChange = (e) => {
    if (e.target.value === "defineFlow") {
      setDefineSetor(false);
      setDefineFlow(e.target.checked);
    }

    if (e.target.value === "defineSetor") {
      setDefineSetor(e.target.checked);

      setDefineFlow(false);
      setSelectedFlowIds([]);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleEditConnection = async (values) => {
    if (selectedQueueIds.length === 0 && selectedFlowIds === 0) {
      toast.error("Fluxo ou Setor são dados obrigatórios! Selecione algum antes de enviar!");
      return;
    }

    console.log("handleEditConnection - values:", values);
    console.log("handleEditConnection - page:", page);

    const editData = {
      ...values,

      queueIds: selectedQueueIds,
      flowIds: selectedFlowIds
    };

    setSelectedFlowIds([]);
    setSelectedQueueIds([]);

    try {
      if (page.username) {
        await api.put(`/facebook/login/${page.username}`, editData, {
          params: {
            connection: "instagram"
          }
        });
        
        onConnect();
      }

      toast.success(i18n.t("connections.instagramModal.success"));

      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {i18n.t("connections.instagramModal.title.edit")}
        </DialogTitle>
        <Formik
          initialValues={facebookPage}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleEditConnection(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("connections.instagramModal.form.farewellMessage")}
                    type="farewellMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>

                <div>
                  <FormControlLabel
                    value="defineFlow"
                    control={
                      <Radio
                        size="small"
                        checked={defineFlow}
                        onChange={handleChange}
                      />
                    }
                    label="Usar Fluxo + Setor"
                    labelPlacement="end"
                  />
                </div>
                <div>
                  <FormControlLabel
                    value="defineSetor"
                    control={
                      <Radio
                        size="small"
                        checked={defineSetor}
                        onChange={handleChange}
                      />
                    }
                    label="Usar somente Setor"
                    labelPlacement="end"
                  />
                </div>
                {defineFlow === true && defineSetor === false ? ( 
                  <FlowSelect
                    selectedFlowIds={selectedFlowIds}
                    onChange={(selectedIdFs) => setSelectedFlowIds(selectedIdFs)}
                  />                  
                ) : (
                  ""
                )}
                <QueueSelect 
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIdQs) => setSelectedQueueIds(selectedIdQs)}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                  {i18n.t("connections.instagramModal.buttons.cancel")}
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting} variant="contained" className={classes.btnWrapper}>
                  {i18n.t("connections.instagramModal.buttons.okEdit")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
ConnectionEditInstagramModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  page: PropTypes.object,
};

export default React.memo(ConnectionEditInstagramModal);
