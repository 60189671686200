import React, { useState, useEffect, useReducer, useContext } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
// assets
import whatsappIcon from "../../assets/channels/WhatsApp_Glyph_Green.png";
import facebookIcon from "../../assets/channels/Facebook_Logo_Primary.png";
import instagramIcon from "../../assets/channels/Instagram_Glyph_Gradient.png";
// components
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
// contexts
import { AuthContext } from "../../context/Auth/AuthContext";
// errors
import toastError from "../../errors/toastError";
// hooks
import { descriptografarDado } from "../../hooks/useCriptografia/index.js";
// services
import api from "../../services/api";
import openSocket from "../../services/socket-io";
// translate
import { i18n } from "../../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};
const reducer2 = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};
const reducer3 = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  // !
  // Context
  const { user, handleLogout } = useContext(AuthContext);
  // History
  const history = useHistory();
  // Reducer
  // # Whatsapp
  const [contacts, dispatch] = useReducer(reducer, []);
  // # Facebook
  const [contactsFacebookPages, dispatchFacebookPages] = useReducer(reducer2, []);
  // # Facebook
  const [contactsInstagram, dispatchInstagram] = useReducer(reducer3, []);
  // State
  // # Whatsapp
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  // # Facebook
  const [loadingFacebookPages, setLoadingFacebookPages] = useState(false);
  const [searchParamFacebookPages, setSearchParamFacebookPages] = useState("");
  const [pageNumberFacebookPages, setPageNumberFacebookPages] = useState(1);
  const [hasMoreFacebookPages, setHasMoreFacebookPages] = useState(false);
  // # Instagram
  const [loadingInstagram, setLoadingInstagram] = useState(false);
  const [searchParamInstagram, setSearchParamInstagram] = useState("");
  const [pageNumberInstagram, setPageNumberInstagram] = useState(1);
  const [hasMoreInstagram, setHasMoreInstagram] = useState(false);

  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // Styles
  const classes = useStyles();

  // ?
  // Email
  const email_agente = Cookies.get("email_agente");
  const email_cliente = Cookies.get("email_cliente");
  const email_supervisor = Cookies.get("email_supervisor");
  // Profile
  let user_profile;
  if (email_cliente) {
    user_profile = "admin";
  }
  else if (email_supervisor) {
    user_profile = "supervisor";
  }
  else if (email_agente) {
    user_profile = "agente";
  }

  const user_email_encrypted = email_agente || email_cliente || email_supervisor;
  const user_email = atob(user_email_encrypted);
  if ( (user?.email && user.email !== user_email) || user?.profile !== user_profile) {
    handleLogout();
  }

  // @
  useEffect(() => {
    dispatch({ type: "RESET_CONTACTS" });
    dispatchFacebookPages({ type: "RESET_CONTACTS" });
    dispatchInstagram({ type: "RESET_CONTACTS" });

    setPageNumber(1);
    setPageNumberFacebookPages(1);
    setPageNumberInstagram(1);
  }, []);

  // @ Fetch contacts
  // # Whatsapp
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          // # Whatsapp
          const { data: whatsappData } = await api.get("/contacts", {
            params: { channel: "whatsapp", searchParam, pageNumber },
          });

          dispatch({ type: "LOAD_CONTACTS", payload: whatsappData.contacts });

          setLoading(false);
          setHasMore(whatsappData.hasMore);
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);
  // # Facebook
  useEffect(() => {
    setLoadingFacebookPages(true);

    const delayDebounceFn2 = setTimeout(() => {
      const fetchFacebookContacts = async () => {
        try {
          // # Facebook
          const { data: facebookData } = await api.get("/contacts", {
            params: { channel: "facebook", searchParamFacebookPages, pageNumberFacebookPages },
          });

          dispatchFacebookPages({ type: "LOAD_CONTACTS", payload: facebookData.contacts });
          setLoadingFacebookPages(false);
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchFacebookContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn2);
  }, [searchParamFacebookPages, pageNumberFacebookPages]);
  // # Instagram
  useEffect(() => {
    setLoadingInstagram(true);

    const delayDebounceFn3 = setTimeout(() => {
      const fetchInstagramContacts = async () => {
        try {
          // # Instagram
          const { data: instagramData } = await api.get("/contacts", {
            params: { channel: "instagram", searchParamInstagram, pageNumberInstagram },
          });

          dispatchInstagram({ type: "LOAD_CONTACTS", payload: instagramData.contacts });
          setLoadingInstagram(false);
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchInstagramContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn3);
  }, [searchParamInstagram, pageNumberInstagram]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.whatsapp.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleImportContact = async () => {
    try {
      await api.post("/contacts/import");

      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.whatsapp.confirmationModal.deleteTitle")} ${
              deletingContact.name
            }?`
            : `${i18n.t("contacts.whatsapp.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleImportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.whatsapp.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.whatsapp.confirmationModal.importMessage")}`}
      </ConfirmationModal>

      <MainHeader>
        <Title>
          <img src={whatsappIcon} width="32px" height="32px" style={{ marginRight: "10px", marginBottom: "5px" }} />
          {i18n.t("contacts.whatsapp.title")}
        </Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.whatsapp.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.whatsapp.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
        style={{ marginBottom: "20px" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.whatsapp.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.number")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> */}
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    {/* <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>

      <MainHeader>
        <Title>
          <img src={facebookIcon} width="32px" height="32px" style={{ marginRight: "10px", marginBottom: "5px" }} />
          {i18n.t("contacts.facebook.title")}
        </Title>

        <MainHeaderButtonsWrapper>
          {/*<TextField
            placeholder={i18n.t("contacts.facebook.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.facebook.buttons.add")}
          </Button>*/}
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
        style={{ marginBottom: "20px" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.facebook.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.facebook.table.id")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contactsFacebookPages.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.facebookUserId}</TableCell>
                  {/*<TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> 
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> 
                  </TableCell>*/}
                </TableRow>
              ))}
              {loadingFacebookPages && <TableRowSkeleton avatar columns={1} />}
            </>
          </TableBody>
        </Table>
      </Paper>

      <MainHeader>
        <Title>
          <img src={instagramIcon} width="32px" height="32px" style={{ marginRight: "10px", marginBottom: "5px" }} />
          {i18n.t("contacts.instagram.title")}
        </Title>

        <MainHeaderButtonsWrapper>
          {/*<TextField
            placeholder={i18n.t("contacts.instagram.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.instagram.buttons.add")}
          </Button>*/}
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.instagram.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.instagram.table.id")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contactsInstagram.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.instagramUserId}</TableCell>
                  {/*<TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> 
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> 
                  </TableCell>*/}
                </TableRow>
              ))}
              {loadingInstagram && <TableRowSkeleton avatar columns={1} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
