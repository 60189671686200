import React from "react";
import PropTypes from "prop-types";
import { Avatar, CardHeader } from "@material-ui/core";
// translate
import { i18n } from "../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();

    return `${year}${month}${day}${hours}`;
  };

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
      title={`${contact.name} #${formatCreatedAt(ticket.createdAt)}${ticket.id}`}
      subheader={
        ticket.user &&
				`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
      }
    />
  );
};
TicketInfo.propTypes = {
  contact: PropTypes.object,
  ticket: PropTypes.object,
  onClick: PropTypes.func,
};

export default TicketInfo;
