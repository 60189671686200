import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const urlFile = process.env.REACT_APP_BACKEND_URL_FILE;

const useStyles = makeStyles(() => ({
  facebookVideo: {
    width: "330px",
    height: "250px",
    paddingBottom: 10
  },
}));

const Video = ({ url, isFullUrl = false }) => {
  // !
  // Ref
  const videoRef = useRef(null);
  // Styles
  const classes = useStyles();

  const fullAudioUrl = isFullUrl ? url : urlFile + url;

  // :

  return (
    <>
      <video ref={videoRef} controls className={classes.facebookVideo}>
        <source src={fullAudioUrl} type="video/mp4"></source>
      </video>
    </>
  );
};
Video.propTypes = {
  url: PropTypes.string.isRequired,
  isFullUrl: PropTypes.bool,
};

export default Video;