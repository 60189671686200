import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  contactsHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0px 6px 6px 6px",
  },
}));

const MainHeader = ({ children }) => {
  // !
  // Styles
  const classes = useStyles();

  return <div className={classes.contactsHeader}>{children}</div>;
};
MainHeader.propTypes = {
  children: PropTypes.node,
};

export default MainHeader;