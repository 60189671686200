import React, { useState, useEffect, useCallback } from "react";
import { format, parseISO } from "date-fns";

import { green } from "@material-ui/core/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet0Bar,
  ErrorOutline
} from "@material-ui/icons";
// assets
import facebookIcon from "../assets/channels/Facebook_Logo_Primary.png";
// errors
import toastError from "../errors/toastError";
// components
import ConnectionEditFacebookPagesModal from "../components/ConnectionEditFacebookPagesModal";
import Title from "../components/Title";
import MainHeader from "../components/MainHeader";
import MainHeaderButtonsWrapper from "../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../components/TableRowSkeleton";
// services
import api from "../services/api";
// styles
import { makeConnectionsListStyles } from "../styles/ConnectionsListStyles";
// translate
import { i18n } from "../translate/i18n";

const ConnectionsListFacebook = () => {
  // States
  const [loadingPages, setLoadingPages] = useState(false);
  const [facebookPagesModalOpen, setFacebookPagesModalOpen] = useState(false);
  const [selectedFacebookPage, setSelectedFacebookPage] = useState(null);
  const [facebookConnected, setFacebookConnected] = useState(false);
  const [facebookPages, setFacebookPages] = useState([]);
  // Styles
  const classes = makeConnectionsListStyles();

  const handleFacebookLogin = () => {
    try {
      window.FB.login(function (response) {
        console.log("FB.Login response", response);
  
        if (response.authResponse) {
          const data = {
            accessToken: response.authResponse.accessToken,
            expiresIn: response.authResponse.expiresIn,
            facebookUserId: response.authResponse.userID
          };
  
          // Envia o token de acesso para o backend
          api.post("/facebook/login", data, {
            params: {
              connection: "facebook"
            }
          }).then(() => {
            getFacebookConnections();
          });
        } else {
          console.error("FB.Login response error", response);
          console.log("User cancelled login or did not fully authorize.");
        }
      }, {
        scope: "pages_messaging,pages_manage_metadata,pages_read_engagement,business_management",
        return_scopes: true
      });
    } catch (err) {
      toastError(err);
    }
  };
  const getFacebookConnections = async () => {
    setLoadingPages(true);

    try {
      const response = await api.get("/facebook/login", {
        params: {
          connection: "facebook"
        }
      });

      const pages = response.data.pages;

      setFacebookPages(pages);
      setLoadingPages(false);

      if (pages.length > 0) {
        setFacebookConnected(true);
      }
    } catch (err) {
      toastError(err);
    }
  };
  // modals
  // # FacebookPages
  const handleEditFacebookPage = facebookPageData => {
    setSelectedFacebookPage(facebookPageData);

    setFacebookPagesModalOpen(true);
  };
  const handleCloseFacebookPagesModal = useCallback(() => {
    setSelectedFacebookPage(null);

    setFacebookPagesModalOpen(false);
  }, [setSelectedFacebookPage, setFacebookPagesModalOpen]);

  useEffect(() => {
    try {
      getFacebookConnections();
    } catch (err) {
      toastError(err);
    }
  }, []);

  return (
    <>
      <ConnectionEditFacebookPagesModal
        open={facebookPagesModalOpen}
        onClose={handleCloseFacebookPagesModal}
        onConnect={getFacebookConnections}
        facebookPageData={selectedFacebookPage}
      />

      <MainHeader>
        <Title>
          <img src={facebookIcon} width="32px" height="32px" style={{ marginRight: "10px", marginBottom: "5px" }} />
          {i18n.t("connections.facebook")}
        </Title>
        <MainHeaderButtonsWrapper>
          <Button variant="contained" color="primary" onClick={() => handleFacebookLogin()}>
            {facebookConnected || facebookPages.length > 0 ?
              i18n.t("connections.buttons.facebook.reconnect")
              : i18n.t("connections.buttons.facebook.connect")
            }
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {i18n.t("connections.table2.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table2.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table2.flow")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table2.queue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table2.lastUpdated")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table2.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingPages ? (
              <TableRowSkeleton columns={6} />
            ) : (
              <>
                {facebookPages?.length > 0 && facebookPages.map(page => (
                  <TableRow key={page.id}>
                    <TableCell>
                      <img src={page.picture} alt="Profile page" style={{ float: "left", marginRight: "10px" }} />
                      <div style={{ marginTop: "5px" }}>{page.name}</div>
                      <a href={`https://www.facebook.com/${page.id}`} target="_blank" rel="noopener noreferrer">{page.id}</a>
                    </TableCell>
                    <TableCell align="center">
                      {page.status === "CONNECTED" ? (
                        <div className={classes.customTableCell}>
                          <CheckCircle style={{ color: green[500], marginRight: "5px" }} />
                          {i18n.t("connections.messaging")}
                        </div>
                      ) : (
                        <div className={classes.customTableCell}>
                          <SignalCellularConnectedNoInternet0Bar color="secondary" style={{ marginRight: "5px" }} />
                          {i18n.t("connections.noMessaging")}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {page.flow !== null ? page.flow : (
                        <div className={classes.customTableCell}>
                          <ErrorOutline color="secondary" style={{ marginRight: "5px" }} />
                          {i18n.t("connections.noFlow")}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {page.queue !== null ? page.queue : (
                        <div className={classes.customTableCell}>
                          <ErrorOutline color="secondary" style={{ marginRight: "5px" }} />
                          {i18n.t("connections.noQueue")}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {format(parseISO(page.updatedAt), "dd/MM/yyyy 'às' HH:mm'h'")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditFacebookPage(page)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default ConnectionsListFacebook;